@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 255, 255, 255;
    --background-end-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
@layer base {
  @font-face {
    font-family: "Helvetica";
    src: url("../fonts/helvetica-fonts/HelveticaLTW05-Bold.woff2")
      format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Helvetica";
    src: url("../fonts/helvetica-fonts/HelveticaLTW05-Light.woff2")
      format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Helvetica";
    src: url("../fonts/helvetica-fonts/HelveticaLTW05-LightOblique.woff2")
      format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Helvetica";
    src: url("../fonts/helvetica-fonts/HelveticaLTW05-Oblique.woff2")
      format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Helvetica";
    src: url("../fonts/helvetica-fonts/HelveticaLTW05-BoldOblique.woff2")
      format("woff2");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Helvetica";
    src: url("../fonts/helvetica-fonts/HelveticaLTW05-Roman.woff2")
      format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-ExtraLight.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-Black.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-ExtraBold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-Thin.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter-fonts/Inter-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
}

@font-face {
  font-family: "PT Serif";
  src: url("../fonts/pt-serif-fonts/PTSerif-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PT Serif";
  src: url("../fonts/pt-serif-fonts/PTSerif-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@layer components {
  .layout-margins {
    @apply mx-4 lg:mx-0;
  }
  .detail-padding {
    @apply px-4 lg:px-8;
  }
  .company-detail-padding {
    @apply px-4 pt-6 lg:px-8;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@supports not (aspect-ratio: 3/2) {
  .aspect-3\/2 {
    > * {
      position: absolute;
      width: 100%;
      > * {
        width: 100%;
        height: 100%;
      }
    }

    &::before {
      float: left;
      padding-top: 66.67%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

@supports not (aspect-ratio: 4/3) {
  .aspect-\[4\/3\] {
    &::before {
      float: left;
      padding-top: 75%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }

    /* > * {
      position: absolute;
      width: 100%;
      > * {
        width: 100%;
        height: 100%;
      }
    } */
  }
}

@font-face {
  font-family: "CostarBrownLight";
  src: url("../fonts/costar-fonts/lineto-costar-brown-light.svg") format("woff"),
    url("../fonts/costar-fonts/lineto-costar-brown-light.woff") format("svg");
  font-weight: normal;
  font-style: normal;
}
